.About {
    display: flex;
    flex-direction: column;
    padding-block: 3vh;
    padding-inline: 5vw;
}

#about-title {
    align-self: flex-start;
}

#about-text {
    align-self: flex-start;
    font-size: min(0.7em, 18px);
    color: rgb(95, 95, 95);
}

#service-container {
    padding: 5vh 0;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    flex-wrap: wrap;
}

.AboutService {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
    align-items: center;
    border-bottom: solid #909090 5px;
    margin-block: 10px;
    box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
    min-width: 100px;
    font-size: min(0.7em, 18px);
    padding: 20px;
    transition: padding 50ms ease-in, margin 50ms ease-in;
}

.AboutService:hover {
    padding: 30px;
    margin: 0;
    transition: padding 50ms ease-in, margin 50ms ease-in;
}

.AboutService > img {
    aspect-ratio: 1/1;
    max-width: 50px;
    padding: 20px;
}

.HireMe {
    display: flex;
    position: relative;
    align-items: center;
    justify-content: center;
    background-color: var(--theme-color-dark);
    align-self: center;
    border-radius: 10px;
    padding: 5px 20px;
    font-size: min(0.9em, 25px);
}

#hiremetext {
    position: relative;
    margin: 0;
    text-decoration: none;
    color: #000000;
}
  
#hiremetext::before {
    content: '';
    position: absolute;
    width: 100%;
    height: 3px;
    border-radius: 3px;
    background-color: #000000;
    bottom: 0;
    left: 0;
    transform-origin: right;
    transform: scaleX(0);
    transition: transform .3s ease-in-out;
}

#hiremetext:hover {
    cursor: pointer;
}

#hiremetext:hover::before {
    transform-origin: left;
    transform: scaleX(1);
}
