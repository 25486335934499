.Education {
    display: flex;
    flex-direction: column;
    padding-block: 2vh;
    padding-inline: 4vw;
}

#education-container {
    display: flex;
    flex-direction: column;
}

.EducationItem {
    display: flex;
    align-items: flex-start;
    padding: 20px 0;
}

.EducationItem > img {
    margin-top: 10px;
    width: min(10vw, 100px);
    padding: 0 min(5vw, 20px);
}

.EducationItemInfo {
    flex-grow: 1;
    font-size: min(0.9em, 22px);
}

.University {
    margin-top: 10px;
    margin-bottom: 3px;
}

.College {
    margin-top: 8px;
    color: #666666;
    font-size: min(0.8em, 16px);
}

.Degree {
    color: #303030;
    font-weight: 600;
    font-size: min(0.8em, 18px);
    margin-bottom: 3px;
}

.Major {
    margin-top: 3px;
    color: #3f3f3f;
    font-weight: 600;
    font-size: min(0.8em, 16px);
}

.Gpa {
    color: #333333;
    font-weight: 600;
    font-size: min(0.8em, 16px);
}

.EduDuration {
    color: rgb(82, 82, 82);
    font-weight: 100;
    font-size: min(0.8em, 16px);
}