.Home {
    overflow: hidden;
    display: flex;
    justify-content: center;
    position: relative;
    height: 100vh;
}

.Home > img {
    position: absolute;
    bottom: 0;
    opacity: 25%;
    height: 100vh;
    mask: linear-gradient(to bottom, rgba(0,0,0,1) 70%,rgba(0,0,0,0));
}

#hi-text {
    position: absolute;
    right: 5%;
    top:30%;
    font-weight: 900;
    font-size: min(2em, 60px);
    opacity: 1;
    animation: hi-animation 1s ease-in forwards;
}

@keyframes hi-animation {
    0% {
        opacity: 0;
    }
    100% {
        opacity: 1;
    }
}

.HomeTextBox {
    position: absolute;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    bottom: 20%;
    right: 5%;
    font-weight: 900;
    font-size: min(1.2em, 35px);
    animation: hi-animation 1s ease-in forwards;
}

.Typed {
    font-weight: 900;
    font-family: 'Courier New', Courier, monospace;
}


@media (max-width:900px) {

}