.App {
    min-height: 100vh;
    display: flex;
    align-items: stretch;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    background-repeat: repeat;
    background-attachment: fixed;
}

@media (max-width: 900px) {
  .Header {
    position: absolute;
    left:-100%;
    max-width: none;
    width: 100vw;
  }
}