.Body{
    max-width: 1000px;
    width: 60vw;
    display: flex;
    flex-direction: column;
    justify-content: stretch;
    background-size: 10px;
    background-color: #ffffff;
}

.HomeHeader {
    position: fixed;
    top: 0;
    display: none;
    align-items: center;
    justify-content: center;
    height: 8vh;
    max-height: 80px;
    width: 100%;
    background: linear-gradient(to bottom, rgb(255, 255, 255) 70%, rgba(255,255,255,0));
    z-index: 1;
}

.HomeHeader > label {
    display: none;
    position: absolute;
    left: 10px;
}

#logo1 {
    display: block;
    height: 70%;
    max-height: 30px;
    display: none;
}

#menu_svg {
    aspect-ratio: 1/1;
    width: 30px;
}

.ScrollTop {
    position: fixed;
    bottom: 20px;
    right: 3vw;
    width: 40px;
    height: 40px;
    background-color: var(--theme-color-dark);
    z-index: 1000;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.9);
    cursor: pointer;
}

.ActiveScrollTop {
    display: none;
    background-color: var(--highlight-fixed) !important;
}


@media (max-width: 900px) {
    .Body{
        max-width: none;
        width: 100%;
    }
    .HomeHeader {
        display: flex;
    }
    .HomeHeader > label {
        display: block;
        position: absolute;
        left: 10px;
    }
    #logo1{
        display: block;
    }
}