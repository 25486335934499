.Contact {
    display: flex;
    flex-direction: column;
    padding-block: 2vh;
    padding-inline: 4vw;
    margin-bottom: 25vh;
}

#contact-container {
    display: flex;
}

#contact-icons {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    flex-wrap: wrap;
    flex-direction: column;
}

.SocialIcon {
    position: relative;
    margin: 20px;
    width: 100px;
    height: 100px;
    box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.5);
    border-radius: 50%;
    overflow: hidden;
    cursor: pointer;
}

.SocialIcon:hover > img {
    width: 63px;
    transition: width 0.2s ease-in;
}

.SocialIcon > img {
    width: 50px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: width 0.1s ease-out;
}

#email-me {
    margin-top: 50px;
    width: 50%;
    display: flex;
    flex-direction: column;
    height: 550px;
}

.inputfieldbox {
    padding: 10px;
    font-size: min(0.7em, 16px);
    font-weight: 100;
    background-color: #ffffff00;
    outline: none;
    margin: 10px 0;
    border: solid 2px black;
    border-radius: 5px;
    font-family: "RocknRoll One", 'Roboto', "courier new";
}

textarea {
    resize: none;
    flex-grow: 1;
}

.inputfieldbox::placeholder {
    font-family: "RocknRoll One", 'Roboto', "courier new";
    font-size: min(0.85em, 20px);

}

button {
    align-self: flex-start;
    font-family: "RocknRoll One", 'Roboto', "courier new";
    font-size: medium;
    width: 100px;
    height: 50px;
    border: solid 2px black;
    background-color: black;
    color: white;
    border-radius: 5px;
    margin: 10px 0;
    cursor: pointer;
}
button:disabled {
    background-color: rgb(78, 78, 78);
}

#errormessage {
    font-size: small;
    margin-bottom: 10px;
    color: #ff0000;
}

textarea::-webkit-scrollbar {
    width: 14px;
}

textarea::-webkit-scrollbar-track {
    background: transparent;
}

textarea::-webkit-scrollbar-track {
    background: transparent;
}

textarea::-webkit-scrollbar-thumb {
    background-color: var(--theme-color-dark);
    border-radius: 20px;
    border: solid 2px white;
}

.AfterSumbit {
    font-family: "RocknRoll One", 'Roboto', "courier new";
    font-size: min(0.85em, 18px);
    font-weight: 100;
}

@media (max-width: 900px) {
    #contact-container {
        flex-direction: column;
    }
    #contact-icons {
        flex-direction: row;
        width: 100%;
    }
    #email-me {
        width: auto;
        padding: 0 40px;
    }
}