.Skills {
    display: flex;
    flex-direction: column;
    padding-block: 2vh;
    padding-inline: 4vw;
}

#skills-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: stretch;
    justify-content: space-between;
}

.SkillSubContainer {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: center;
    width: 48%;
    box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.17);
    margin-bottom: 20px;
}

.SkillSubHeader {
    width: 100%;
    font-size: min(0.85em, 20px);
    text-align: center;
    margin: 10px 0;
    padding-bottom: 5px;
    border-bottom: solid 5px #7a7a7a;
}

.SkillItem {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
    font-size: min(0.5em, 12px);
    margin: 10px 5px;
    height: 75px;
}

.SkillImg {
    width: 50px;
}
.SkillName {
    margin-top: 5px;
}