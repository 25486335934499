.Projects {
  padding-block: 2vh;
  padding-inline: 4vw;
}
#projects-container {
  display: flex;
  flex-wrap: nowrap;
  flex-direction: column;
}

.FlexRowGroup {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}

.FlexRowGroupItem {
  width: 300px;
  height: 200px;
  position: relative;
  margin-bottom: 40px;
  margin-inline: 10px;
  display: flex;
  justify-content: center;
  z-index: 1;
  box-shadow: 0px 0px 56px -8px rgba(0, 0, 0, 0.3);
  border: 1px solid #000;
}

.FlexRowGroupItem > a {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 5;
}

.FlexRowGroupItem > img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translateX(-50%) translateY(-50%);
}

.FlexRowGroupItem > div {
  width: 100%;
  height: 100%;
  position: relative;
  font-size: large;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 80%
  );
  cursor: pointer;
  z-index: 2;
  transition: all 0.3s ease-out;
}

.FlexRowGroupItem > div::before {
  position: absolute;
  content: "";
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background: linear-gradient(
    180deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.9) 30%
  );
  z-index: 1;
  cursor: pointer;
  transition: all 0.3s ease-out;
  opacity: 0;
}

.FlexRowGroupItem > div > p {
  position: absolute;
  width: 100%;
  bottom: 0;
  text-align: center;
  z-index: 3;
  transition: all 0.3s ease-out;
}

.FlexRowGroupItem > a:hover ~ div::before {
  opacity: 1;
}

.FlexRowGroupItem > a:hover ~ div > p {
  bottom: 50%;
  transform: translateY(100%);
}
